import { isArray } from 'utils';
import { Natures } from 'consts';

/**
 *
 * @param natures {string[]}
 * @return {{pollByEnabled: boolean, pollByLinkEnabled: boolean, testContentRecAtEndEnabled: boolean,
 * reportingEnabled: boolean, resultsDisabled: boolean, creEnabled: boolean,
 * adEngageEnabled: boolean, complianceFirstEnabled: boolean, gameEnabled: boolean,
 * expandableComplianceEnabled: boolean, muidEnabled: boolean,
 * horizontalOptionsEnabled: boolean, backButtonEnabled: boolean, backgroundImageEnabled: boolean,
 * testQuestionDynamicNextEnabled: boolean, testNoAutoAdvanceEnabled: boolean,
 * testShareModalEnabled: boolean}}
 */
const evalNatures = (natures = []) => {
  const naturesSet = new Set(isArray(natures) ? natures : []);

  const adEngageEnabled = naturesSet.has(Natures.RootbeerEnabled);
  const complianceFirstEnabled = naturesSet.has(Natures.ComplianceFirst);
  const creEnabled = naturesSet.has(Natures.ContentRecommendationEngineEnabled);
  //TODO: Once A/B testing is over we can remove TestGameEnabled and possibly GameEnabled
  const gameEnabled = naturesSet.has(Natures.GameEnabled) || naturesSet.has(Natures.TestGameEnabled);
  const muidEnabled = naturesSet.has(Natures.MUID);
  const pollByEnabled = naturesSet.has(Natures.ShowPollByCS);
  const pollByLinkEnabled = naturesSet.has(Natures.ShowPollByCSLink);
  const reportingEnabled = naturesSet.has(Natures.QuestionReporting);
  const resultsDisabled = naturesSet.has(Natures.ResultsDisabled);
  const expandableComplianceEnabled = naturesSet.has(Natures.ExpandableCompliance);
  const horizontalOptionsEnabled = naturesSet.has(Natures.HorizontalOptions);
  const gamTargetingEnabled = naturesSet.has(Natures.GAMTargeting);
  const backButtonEnabled = naturesSet.has(Natures.BackButtonEnabled);
  const backgroundImageEnabled = naturesSet.has(Natures.BackgroundImgEnabled);

  //TEST Natures
  //TODO: For now we have 2 natures for cre. Eventually after some prod testing, we may want to bring it to only one,
  // that may have an extra position attribute to identify where to put the cre list
  const testContentRecAtEndEnabled = naturesSet.has(Natures.ContentRecommendationEngineEndEnabled);
  const testQuestionDynamicNextEnabled = naturesSet.has(Natures.TestQuestionDynamicNext);
  const testNoAutoAdvanceEnabled = naturesSet.has(Natures.TestNoAutoAdvance);
  const testShareModalEnabled = naturesSet.has(Natures.TestShareModal);

  return {
    adEngageEnabled, complianceFirstEnabled, creEnabled, gameEnabled,
    pollByEnabled, pollByLinkEnabled,
    reportingEnabled, expandableComplianceEnabled, resultsDisabled,
    muidEnabled, horizontalOptionsEnabled, gamTargetingEnabled,
    backButtonEnabled, backgroundImageEnabled,

    //TEST Natures
    testContentRecAtEndEnabled, testQuestionDynamicNextEnabled,
    testNoAutoAdvanceEnabled, testShareModalEnabled
  };
};

export default evalNatures;
