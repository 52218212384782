import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { WidgetState } from 'consts';
import isArray from 'utils/isArray';
import isNullOrUndefOrEmpty from 'utils/isNullOrUndefOrEmpty';
import toObject from 'utils/toObject';

export const backgroundImageGradient = 'linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25))';

/**
 *
 * @param {boolean} [backgroundImageEnabled ]
 * @param {Object} [questionsData]
 * @param {number} [questionIndex]
 * @param {number} [widgetState]
 * @return {{imgUrl: (string|undefined), showBackgroundImg: boolean,
 * backgroundImageStyles: (string | CSSProperties | SignalLike<string | CSSProperties | undefined> | undefined)}}
 */
const useBackgroundImage = ({
  backgroundImageEnabled = false,
  questionsData,
  questionIndex = 0,
  widgetState = 0
}) => {
  const [imgUrl, setImgUrl] = useState(undefined);
  const showBackgroundImg = backgroundImageEnabled
    && !isNullOrUndefOrEmpty(imgUrl)
    && widgetState === WidgetState.Questions;

  useEffect(() => {
    const { questions = [] } = toObject(questionsData);
    setImgUrl((isArray(questions) && questions.length > 0) ? questions[questionIndex]?.imgUrl : undefined);
  }, [questionsData, questionIndex]);

  return {
    showBackgroundImg,
    imgUrl,
    backgroundImageStyles: showBackgroundImg ? {
      backgroundSize: 'cover',
      backgroundImage: `${backgroundImageGradient}, url(${imgUrl})`
    }: undefined
  };
};

useBackgroundImage.propTypes /* remove-proptypes */ = {
  widgetState: PropTypes.number,
  questionsData: PropTypes.object,
  questionIndex: PropTypes.number,
};

export default useBackgroundImage;
