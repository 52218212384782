const Natures = {
  RootbeerEnabled: 'rootbeer-enabled',
  ResultsDisabled: 'results-disabled',
  MUID: 'muid',                                                     //SOF-1751
  ComplianceFirst: 'compliance-first',
  GameEnabled: 'game-enabled',                                      //CS-733 NOTE: This is the feature version of the gamification nature
  ExpandableCompliance: 'expandable-compliance',
  QuestionReporting: 'question-reporting-enabled',
  ShowPollByCS: 'show-poll-by-cs',
  ShowPollByCSLink: 'show-poll-by-cs-link',                         //CS-2193
  ContentRecommendationEngineEnabled: 'cre',
  HorizontalOptions: 'horizontal-options',                          //CS-333 && CS-713
  GAMTargeting: 'gam-targeting',                                    //CS-1131
  BackButtonEnabled: 'back-button-enabled',                         //CS-1146
  BackgroundImgEnabled: 'background-img-enabled',                   //CS-2193

  //TEST NATURES BELOW
  ContentRecommendationEngineEndEnabled: 'testContentRecEnd',       //COL-60
  TestQuestionDynamicNext: 'testQuestionDynamicNext',               //CS-309
  TestNoAutoAdvance: 'testNoAutoAdvance',                           //CS-309
  TestGameEnabled: 'testGameEnabled',                               //CS-733
  TestShareModal: 'testShareModal',                                 //CS-714
};

export default Natures;
